import { Button, Col, Container, Row } from 'react-bootstrap'
import React from 'react'
import { Link } from 'react-router-dom'
import logoColor from '../img/logo/logoColor.png'
import logoWhite from '../img/logo/logoWhite.png'
import { FaAngellist, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/all'

export default function Footer() {
    return (
        <div id="footer" className="bg-black">
            <br/><br></br><Container>
                <Row>
                    <Col className="text-center text-md-left">
                        <ul className="list-unstyled">
                            <li className="mb-3"><h6 className="font-w700 text-uppercase text-white">Legal</h6></li>
                            <li className="mb-3 text-white"><a href="www.getpupil.com">Privacy Policy</a></li>
                            <li className="mb-3 text-white"><a href="www.getpupil.com">Terms of Use</a></li>
                            <li className="mb-3 text-white"><a href="www.getpupil.com">Corporate Involvement</a></li>
                        </ul>
                    </Col>
                    <Col md={4} className="text-center text-md-left">
                        <h6 className="font-w700 text-uppercase text-white">Join our newsletter!</h6>
                        <p className="text-white">Receive information about Pupil and stay in the loop!</p>
                        <a href="https://tinyurl.com/pupilsofpupil">
                            <Button variant="purple" className="btn-sm font-w600">Sign Up</Button>
                        </a>
                    </Col>
                </Row>
                <hr className="my-5"/>
                <div className="text-center text-md-left d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div>
                        <img className="mb-4" src={logoWhite} alt="Logo" height={30}/>
                    </div>
                    <p className="text-white font-w500">©2021 getpupil.com. All rights reserved.</p>
                    <div className="d-flex">
                        <a className="mr-3" href="https://www.linkedin.com/company/pupilllc">
                            <div className="brand1"><FaLinkedinIn/></div>
                        </a>
                        <a className="mr-3" href="https://twitter.com/getpupil">
                            <div className="brand1"><FaTwitter/></div>
                        </a>
                        <a className="mr-3" href="https://www.instagram.com/getpupil/">
                            <div className="brand1"><FaInstagram/></div>
                        </a>
                        <a className="mr-3" href="https://angel.co/company/pupil-4">
                            <div className="brand1"><FaAngellist/></div>
                        </a>
                    </div>
                </div>
            </Container><br></br>
        </div>
    )
}

// TODO: Responsive navbar from Treact
