import { useRef, useState } from 'react'
import Job from '../components/Job'
import { Button, Col, Container, Row, Navbar, Nav } from 'react-bootstrap'
import { getJobs } from '../jobIndex'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import hero1 from '../img/svg/hero1.svg'
import hero2 from '../img/svg/hero2.svg'
import hero3 from '../img/svg/hero3.svg'
import hero4 from '../img/svg/hero4.svg'
import { BsChevronLeft, BsChevronRight, FiChevronLeft, FiChevronRight } from 'react-icons/all'


export default function Home() {
    const carouselRef = useRef<AliceCarousel>(null)
    const carouselsec = useRef<AliceCarousel>(null)

    const [currentFilter, setCurrentFilter] = useState('all')

    const jobs = getJobs()

    return (
        <>
            <Container className="mb-5">
                <div className="d-none d-md-block">
                    <AliceCarousel
                        ref={carouselRef}
                        disableDotsControls
                        disableButtonsControls
                        infinite
                        items={[
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div style={{ width: '40%' }}>
                                    <h6 className="font-w600 font-xs text-uppercase text-black-50">Core Values</h6>
                                    <h1 className="font-w900">Building the future</h1>
                                    <h6>We're dedicated to our community and helping them connect and learn from people!</h6>
                                </div>
                                <img src={hero1} style={{ width: '50%', pointerEvents: 'none' }}/>
                            </div>,
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div style={{ width: '40%' }}>
                                    <h6 className="font-w600 font-xs text-uppercase text-black-50">Core Values</h6>
                                    <h1 className="font-w900">Become an innovator</h1>
                                    <h6>We're open to ideas at all times; just ask and deliver!</h6>
                                </div>
                                <img src={hero2} style={{ width: '50%', pointerEvents: 'none' }}/>
                            </div>,
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div style={{ width: '40%' }}>
                                    <h6 className="font-w600 font-xs text-uppercase text-black-50">Core Values</h6>
                                    <h1 className="font-w900">Climb the mountain</h1>
                                    <h6>We’re motivated by challenge, pursuit and mission!</h6>
                                </div>
                                <img src={hero3} style={{ width: '50%', pointerEvents: 'none' }}/>
                            </div>,
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div style={{ width: '40%' }}>
                                    <h6 className="font-w600 font-xs text-uppercase text-black-50">Core Values</h6>
                                    <h1 className="font-w900">Learn and deliver</h1>
                                    <h6>We’re aim to grow with those around us and champion our products as a team!</h6>
                                </div>
                                <img src={hero4} style={{ width: '50%', pointerEvents: 'none' }}/>
                            </div>
                        ]}
                    />
                    <div className="position-relative">
                        <div className="position-absolute" style={{ bottom: 50 }}>
                            <BsChevronLeft className="mr-2" style={{ cursor: 'pointer' }} size={35} onClick={(e) => carouselRef.current?.slidePrev(e)}/>
                            <BsChevronRight style={{ cursor: 'pointer' }} size={35} onClick={(e) => carouselRef.current?.slideNext(e)}/>
                        </div>
                    </div>
                </div>
                <div className="d-none d-md-flex justify-content-between" style={{ marginBottom: 100 }}>
                    <div className="d-flex" style={{ width: '20%', cursor: 'pointer' }} onClick={() => carouselRef.current?.slideTo(0)}>
                        <h6 className="font-w900 mr-2">01</h6>
                        <h6 className="font-w600">Building the future</h6>
                    </div>
                    <div className="d-flex" style={{ width: '20%', cursor: 'pointer' }} onClick={() => carouselRef.current?.slideTo(1)}>
                        <h6 className="font-w900 mr-2">02</h6>
                        <h6 className="font-w600">Become an innovator</h6>
                    </div>
                    <div className="d-flex" style={{ width: '20%', cursor: 'pointer' }} onClick={() => carouselRef.current?.slideTo(2)}>
                        <h6 className="font-w900 mr-2">03</h6>
                        <h6 className="font-w600">Climb the mountain</h6>
                    </div>
                    <div className="d-flex" style={{ width: '20%', cursor: 'pointer' }} onClick={() => carouselRef.current?.slideTo(3)}>
                        <h6 className="font-w900 mr-2">04</h6>
                        <h6 className="font-w600">Learn and deliver</h6>
                    </div>
                </div>
                <div className="d-block d-md-none" style={{ marginLeft: 10, marginBottom: 100, marginTop: 15 }}>
                        <AliceCarousel
                            ref={carouselsec}
                            disableDotsControls
                            disableButtonsControls
                            infinite
                            items={[
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <img src={hero1} style={{width: '100%', pointerEvents: 'none' }}/>
                                </div>,
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <img src={hero2} style={{width: '100%', pointerEvents: 'none' }}/>
                                </div>,
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <img src={hero3} style={{width: '100%', pointerEvents: 'none' }}/>
                                </div>,
                                 <div className="d-flex align-items-center justify-content-between mb-4">
                                    <img src={hero4} style={{width: '100%', pointerEvents: 'none' }}/>
                                </div>
                            ]}
                        /><br/><br></br><br></br>
                            <div className="position-relative">
                                <div className="position-absolute" style={{ bottom: 25, left: -7}}>
                                    <BsChevronLeft className="mr-2" style={{ cursor: 'pointer' }} size={35} onClick={(e) => carouselsec.current?.slidePrev(e)}/>
                                    <BsChevronRight style={{ cursor: 'pointer' }} size={35} onClick={(e) => carouselsec.current?.slideNext(e)}/>
                                </div>
                            </div>
                            <h3 className="font-w600">Building the future</h3>
                            <p>We're dedicated to our community and helping them connect and learn from people!</p>
                            <Nav.Item className="text-left">
                            <a href="#postings">
                                <Button className="btn-md font-w600" variant="black">Explore Roles</Button>
                            </a>
                            </Nav.Item>    
                        </div>
                    <div>
                    </div>

                <hr  style={{
                                color: '#000000',
                                backgroundColor: '#000000',
                                height: .5,
                                borderColor : '#000000'
                            }}/>

                <br/><br></br><br></br><Row>
                    <Col xs={12} md={6} className="mb-5">
                        <h6 className="font-w600 font-xs text-uppercase text-black-50 mb-4">Departments</h6>
                        <div className="d-flex flex-column">
                            <button className={'dept-select-btn' + (currentFilter === 'all' ? ' active' : '')} onClick={() => setCurrentFilter('all')}>
                                All Departments ({jobs.business_development.length + jobs.marketing.length + jobs.product_design.length + jobs.engineering.length})
                            </button>
                            <button className={'dept-select-btn' + (currentFilter === 'business_development' ? ' active' : '')} onClick={() => setCurrentFilter('business_development')}>
                                Business Development ({jobs.business_development.length})
                            </button>
                            <button className={'dept-select-btn' + (currentFilter === 'marketing' ? ' active' : '')} onClick={() => setCurrentFilter('marketing')}>
                                Marketing ({jobs.marketing.length})
                            </button>
                            <button className={'dept-select-btn' + (currentFilter === 'product_design' ? ' active' : '')} onClick={() => setCurrentFilter('product_design')}>
                                Product Design ({jobs.product_design.length})
                            </button>
                            <button className={'dept-select-btn' + (currentFilter === 'engineering' ? ' active' : '')} onClick={() => setCurrentFilter('engineering')}>
                                Engineering ({jobs.engineering.length})
                            </button>
                        </div>
                    </Col>
                    <div id="postings"></div>
                    <Col xs={12} md={6} style={{ height: 420, overflowY: 'scroll' }}>
                        <h6 className="font-w600 font-xs text-uppercase text-black-50 mb-4">Featured Roles</h6>
                        {currentFilter === 'all'
                            ? <>{jobs['business_development'].concat(jobs['marketing']).concat(jobs['product_design']).concat(jobs['engineering']).map(job => <Job id={job.id} title={job.title} location={job.location}/>)}</>
                            : <>{jobs[currentFilter].map(job => <Job id={job.id} title={job.title} location={job.location}/>)}</>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}