import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Job({ id, title, location }) {
    return (
        <Link to={'/' + id}>
            <Card className="mb-3" style={{ cursor: 'pointer' }}>
                <h5 className="font-w600">{title}</h5>
                <h6 className="text-gray">{location}</h6>
            </Card>
        </Link>
    )
}