import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import ScrollToTop from './components/ScrollToTop'
import Home from './pages/Home'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import JobPage from './pages/JobPage'

export default function App() {
    return (
        <BrowserRouter>
            <Navigation/>
            <ScrollToTop/>
            <Switch>
                <Route exact path="/:id" component={JobPage}/>
                <Route component={Home}/>
            </Switch>
            <Footer/>
        </BrowserRouter>
    )
}
