import jobs from './jobs.json'

interface Position {
    id: number,
    title: string,
    location: string,
    posting: string,    
    description: string,
    experience: {
        summary: string,
        bullets: string[],
    },
    duties: string[],
    benefits: string[],
}

interface Jobs {
    business_development: Position[],
    marketing: Position[],
    product_design: Position[],
    engineering: Position[],
}

export function getJobs() {
    return jobs as unknown as Jobs
}

export function getJobByID(id) {
    for (let positions of Object.values(getJobs())) {
        let pos = positions.find(position => position.id === parseInt(id))
        if (pos) return pos
    }
    return undefined
}
