import { getJobByID } from '../jobIndex'
import { useParams } from 'react-router'
import Error404 from './Error404'
import { Container } from 'react-bootstrap'
import { Navbar, Nav, Button } from 'react-bootstrap'


export default function JobPage() {
    const { id } : any = useParams();
    const job = getJobByID(id)

    if (job === undefined) return <Error404/>

    return (
        <>
            <div className="d-flex align-items-center mb-5" style={{ height: 250, backgroundColor: '#7A60E4' }}>
                <Container>
                    <br/><h1 className="text-white font-w900 mb-3 job">{job.title}</h1>
                    <h5 className="text-white font-w500 jobsmall">{job.location}</h5><br/>
                    <Nav.Item className="text-left">
                        <a href={job.posting}>
                            <Button className="btn-md font-w600" variant="white">Apply</Button>
                        </a>
                    </Nav.Item><br></br>               
                </Container>
            </div>
            <Container className="mb-5">
                <h5 className="font-w600">Description</h5>
                <p>{job.description}</p>

                <h5 className="font-w600">What we're looking for</h5>
                <p>{job.experience.summary}</p>
                <ul>
                    {job.experience.bullets.map(li => <li>{li}</li>)}
                </ul>
                <h5 className="font-w600">Working virtually, you will</h5>
                <ul>
                    {job.duties.map(li => <li>{li}</li>)}
                </ul>
                <h5 className="font-w600">Benefits</h5>
                <ul>
                    {job.benefits.map(li => <li>{li}</li>)}
                </ul>
            </Container>
        </>
    )
}