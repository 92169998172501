import { useState } from 'react'
import { Navbar, Nav, Button } from 'react-bootstrap'
import logoColor from '../img/logo/logoColor.png'
import { Link } from 'react-router-dom'
import { RiCloseFill, RiMenuLine } from 'react-icons/all'

export default function Navigation() {
    const [mobile, setMobile] = useState(false)

    return (
        <Navbar id="navbar" expand="xl" className="sticky-top">
            <Navbar.Brand>
                <Link to="/">
                    <img src={logoColor} alt="Logo"/>
                </Link>
            </Navbar.Brand>
            <RiMenuLine className="d-block d-xl-none" size={30} onClick={() => setMobile(!mobile)}/>
            <Nav className={'d-none d-xl-flex align-items-center'}>
                    <Nav.Item className="text-center ml-3">
                    <a href="https://tinyurl.com/pupilsofpupil">
                        <Button className="btn-md font-w600" variant="purple">Sign Up</Button>
                    </a>
                </Nav.Item>
            </Nav>
            <div className={'mobile-nav d-flex d-xl-none flex-column align-items-center' + (mobile ? ' show' : '')}>
                <a onClick={() => setMobile(!mobile)} style={{position: 'absolute', top: 15, right: 20}}><RiCloseFill size={25}/></a>
                <a href="https://tinyurl.com/pupilsofpupil">
                    <Button className="btn-md font-w600" variant="purple">Sign Up</Button>
                </a>
            </div>
        </Navbar>
    )
}